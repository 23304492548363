import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/www/src/templates/default-mdx-page-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}<a parentName="h1" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#getting-started"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h1>
    <h2 {...{
      "id": "installation-and-usage"
    }}>{`Installation and usage`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#installation-and-usage"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`Paragon components require React 16 or higher. To install Paragon into your project:`}</p>
    <p>{`In terminal:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm i --save @openedx/paragon
`}</code></pre>
    <p>{`In your React project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { ComponentName } from '@openedx/paragon';
`}</code></pre>
    <h2 {...{
      "id": "scss-foundation"
    }}>{`SCSS Foundation`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#scss-foundation"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`Usage with no theme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import "@openedx/paragon/scss/core/core.scss";
`}</code></pre>
    <p>{`Usage with a theme:`}</p>
    <p>{`When working with a theme the order of imports is important: if you need to override Paragon's variables you must import theme's variables before Paragon core (that's because all variables in Paragon use `}<inlineCode parentName="p">{`!default`}</inlineCode>{` flag which allows you to override them before importing, you can read more `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation/variables#default-values"
      }}>{`here`}</a>{`), while to override Paragon's styles, the theme's style overrides must be imported after.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import "@my-brand/fonts.scss";
@import "@my-brand/variables.scss";
// Import the Paragon core after setting brand-themed variables.
@import "@openedx/paragon/scss/core/core.scss";
// Import brand overrides after the Paragon core.
@import "@my-brand/overrides.scss";
`}</code></pre>
    <p>{`Note that including fonts will affect load performance.  In some pages you may choose not to load the custom font.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      